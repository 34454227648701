import { memo } from 'react'

const EventViewSkeleton = () => {
  return (
    <div className="w-full flex-1 flex justify-center items-center relative bg-white">
      <div className="max-w-content text-center px-3 py-3 large:py-8 large:px-0 w-full h-full flex flex-col justify-center items-center">
        <div className="w-2/3 large:w-full h-4 large:h-10 bg-skeleton animate-pulse bg-skeleton animate-pulse my-2.5 large:my-4" />
        <div className="w-full h-full bg-skeleton animate-pulse rounded-md" />
      </div>
    </div>
  )
}

export default memo(EventViewSkeleton)
