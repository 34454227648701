import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store'
import { useGetQuestionsQuery } from 'store/api.slice'
import { closeDialog, DialogType, openDialog } from 'store/dialogs.slice'
import { EventStatus, IGetQuestionsResponse, ISlot, ITimer, QuestionStatus } from '../types'
import { requestTimeout } from './utils'

export const useTheme = () => {
  // get data from redux

  useEffect(() => {
    setTimeout(() => {
      document.documentElement.style.setProperty('--background', '#0046AB')
      document.documentElement.style.setProperty('--button', 'blue')
    }, 3000)
  }, [])
}

export const useQuestions = ({ event }: { event: string }): IGetQuestionsResponse & { isLoading: boolean } => {
  const {
    query: { cid },
  } = useRouter()
  const { data, isLoading } = useGetQuestionsQuery({ eventCode: event, activityCode: cid as string })

  return {
    isLoading,
    ...(data || {
      questions: [],
      target_correct_answers: 0,
      prize: {},
      status: QuestionStatus.ANSWER,
    }),
  }
}

export const useDialogs = (
  dialogType: DialogType,
): Record<string, unknown> & {
  isOpen: boolean
  open: (payload?: Record<string, unknown>) => void
  close: () => void
} => {
  const dialogData = useSelector((state: RootState) => state.dialogs?.[dialogType]) as Record<string, unknown> & {
    isOpen: boolean
  }
  const dispatch = useAppDispatch()

  const open = (payload?: Record<string, unknown>) => dispatch(openDialog({ dialogType, ...payload }))
  const close = () => dispatch(closeDialog({ dialogType }))

  return { open, close, ...dialogData }
}

export const useCurrentEventStatus = (slots?: ISlot[]) => {
  const [currentEventStatus, setCurrentEventStatus] = useState<EventStatus | undefined>()

  useEffect(() => {
    if (!slots) return

    let timer: ITimer
    const timeToOngoing = slots[0]?.begin_time - Date.now()
    const timeToEnd = slots[0]?.end_time - Date.now()

    if (timeToOngoing > 0) {
      setCurrentEventStatus('up-coming')
      timer = requestTimeout(() => setCurrentEventStatus('on-going'), timeToOngoing)
    } else if (timeToEnd > 0) {
      setCurrentEventStatus('on-going')
      timer = requestTimeout(() => setCurrentEventStatus('ended'), timeToEnd)
    } else setCurrentEventStatus('ended')

    return () => timer?.clear()
  }, [slots, currentEventStatus])

  return { currentEventStatus }
}
