const EventTabsSkeleton = () => {
  return (
    <div className="bg-white flex justify-between items-center">
      {[1, 2, 3, 4].map((item) => (
        <div className="flex-1 flex flex-col justify-center items-center pt-3 pb-1 large:pt-5 large:pb-4" key={item}>
          <div className="w-9 h-4 large:w-[120px] large:h-10 bg-skeleton animate-pulse" />
          <div className="w-12 h-3 large:w-[170px] large:h-8 bg-skeleton animate-pulse mt-1 large:mt-2" />
        </div>
      ))}
    </div>
  )
}

export default EventTabsSkeleton
