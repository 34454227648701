import type { NextPage } from 'next'
import { useAppDispatch } from 'store'
import { useGetActivityByCodeQuery } from 'store/api.slice'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { getPersistentReminderList } from 'shared/persistent'
import { setError, setReminderList, setSelectingEvent } from 'store/app.slice'
import { appApiAxios, IAxiosBaseQueryError } from 'shared/axios-settings'
import EventViewSkeleton from 'components/EventViewSkeleton'
import EventTabsSkeleton from 'components/EventTabsSkeleton'
import dynamic from 'next/dynamic'
import { getVariables, setTimeOffset } from 'shared/utils'
import { useCurrentEventStatus } from 'shared/hook'

const EventTabs = dynamic(() => import('components/EventTabs'))
const EventView = dynamic(() => import('components/EventView'))
const ErrorScreen = dynamic(() => import('components/ErrorScreen'))
const Toast = dynamic(() => import('components/Toast'))
const LoadingToast = dynamic(() => import('components/LoadingToast'))

const Home: NextPage = () => {
  const router = useRouter()
  const {
    isReady,
    query: { cid },
  } = router
  const dispatch = useAppDispatch()
  const { data, error } = useGetActivityByCodeQuery(cid as string, {
    skip: !isReady || !cid,
  })

  useEffect(() => {
    if (isReady) {
      if (cid) dispatch(setReminderList(getPersistentReminderList()))
      else dispatch(setError({ data: { code: 404 } }))
    }
  }, [isReady])

  useEffect(() => {
    if (error) {
      const { status, data } = error as IAxiosBaseQueryError
      if (status === 404 && data?.code === 3001) router.replace(`/end/?cid=${cid}`)
    }
  }, [error])

  useEffect(() => {
    if (data) {
      if (!data.slots || data.slots.length === 0) router.replace(`/end/?cid=${cid}`)
      else dispatch(setSelectingEvent(data.current_event_code))
    }
  }, [data])

  useEffect(() => {
    appApiAxios.get(`${getVariables().apiURL}/delta/time/`).then((res) => {
      const serverTime = res.data.data?.['unix_ts']
      setTimeOffset(serverTime)
    })
  }, [])

  const { currentEventStatus } = useCurrentEventStatus(data?.slots)

  return (
    <main className="h-screen flex flex-col">
      {!data?.slots ? (
        <>
          <EventTabsSkeleton />
          <EventViewSkeleton />
        </>
      ) : (
        <>
          <EventTabs slots={data.slots} currentEventStatus={currentEventStatus} />
          <EventView slots={data.slots} currentEventStatus={currentEventStatus} />
        </>
      )}
      <Toast />
      <LoadingToast />
      <ErrorScreen />
    </main>
  )
}

export default Home
