export type ITimer =
  | {
      id: number
      clear: () => void
    }
  | undefined

export enum AnswerStatus {
  CORRECT = 'CORRECT',
  WRONG = 'WRONG',
  UNANSWERED = 'UNANSWERED',
}

export interface ISlot {
  begin_time: number
  end_time: number
  event_code: string
  config: {
    background_color: string
    button_text_color: string
    image_session_ended_url: 'string'
    page_number_text_color: string
    question_text_color: string
    question_zone_background_color: string
    reminder_content: string
    reminder_title: string
    result_text_color: string
    target_not_reached_image_url: string
    target_reached_image_url: string
    upcoming_image_url: string
    upcoming_text_with_color: string
  }
}

export interface IGetActivityResponse {
  current_event_code: string
  image_session_ended_url: string
  slots: ISlot[]
}

export interface IQuestion {
  question_id: number
  question_text: string
  question_image_url: string
  options: string[]
  correct_option_index: number
  answer_explanation: 'string'
  user_selected_options: number[]
  status: keyof typeof AnswerStatus
}

export enum QuestionStatus {
  REANSWER = 'REANSWER',
  ANSWER = 'ANSWER',
}

export interface IGetQuestionsResponse {
  current_question_index?: number
  questions: IQuestion[]
  target_correct_answers: number
  status: keyof typeof QuestionStatus
  prize:
    | {
        image_prize_icon: string
        text_prize_desc: string
        text_prize_name: string
      }
    | Record<string, never>
}

export interface IAnswerResponse {
  chance_info: { total_balance: number }
  status: keyof typeof AnswerStatus
  correct_option_index: number
  answer_explanation: string
  user_selected_option_index: number
}

export type EventStatus = 'on-going' | 'up-coming' | 'ended'

export type ShareSNSType =
  | 'chat'
  | 'facebookLink'
  | 'zaloTimeline'
  | 'facebookMessenger'
  | 'copyLink'
  | 'whatsapp'
  | 'instagram'
  | 'telegram'
  | 'lineChat'
  | 'twitter'

export interface IFriendInfo {
  contact_name: string
  shared: boolean
  online_status: 0 | 1
  portrait: string
  relation_type: number[]
  user_id: number
  user_name: string
}

export interface IGetShareLinkData {
  shareID: string
  shareLink: string
  shareContent: string
  shareImg: string
}

export type ShareObjectType = 'event' | 'quizResult'

export interface ISharingHandler {
  activityCode: string
  eventCode: string
  snsType: ShareSNSType
  objectType: ShareObjectType
  micrositeID: string
}

export type TaskType = 'act_follow_shop' | 'act_view_shop' | 'act_like_item' | 'act_view_item'

export type PrizeType = 'prize_chance'

export type TaskStatus = 'AVAILABLE' | 'COMPLETED'

export interface ITask {
  id: number
  task_name: string
  index: number
  action_type: TaskType
  action_count: number
  prize_type: PrizeType
  prize_quantity: number
  status: TaskStatus
  assets: {
    shop_id: number
    item_id: number
    task_logo: string
    task_title: string
  }
}

export interface IGetTasksResponse {
  tasks: ITask[]
  prize_awarded?: {
    type: PrizeType
    quantity: number
  }[]
}

export interface IUserChanceResponse {
  total_balance: number
  chances_each_sharing: number
  remaining_share_times: number
}

export type TopLevelDomainType = 'vn' | 'sg' | 'com.my' | 'co.th' | 'tw' | 'co.id' | 'br'

export interface IVars {
  baseURL: string
  apiURL: string
  universalShareURL: string
  micrositePrefix: string
  universalMicrositePrefix: string
  shopeeLoginURL: string
  shopeeURL: string
}

export type IRegion = 'vn' | 'sg' | 'my' | 'th' | 'tw' | 'id' | 'br'
